import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  updateDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import mailer from "../service/api/mailer";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { useAuth } from "../components/AuthContext";
import { useSearchParams } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useGeneralContext } from "../context/GeneralContext";
import { decrypt } from "../service/encryption/Encryption";

const AddressVerify = () => {
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [code, setCode] = useState("");
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const navigate = useNavigate();
  const { setRoleBasedRoute, login } = useAuth();
  const { email, dupAddressData } = useGeneralContext();

  // Initialize verification data from URL or context
  useEffect(() => {
    const checkOriginalUserVerification = async () => {
      // Try to get user data from localStorage first
      const storedUser = localStorage.getItem("addressVerifyUser");
      let user;

      if (storedUser) {
        user = JSON.parse(storedUser);
      } else {
        if (email) {
          user = await getUserByEmail(email);
        } else {
          user = await getUserByEmail(auth.currentUser.email);
        }
        // Store user data in localStorage
        localStorage.setItem("addressVerifyUser", JSON.stringify(user));
      }

      setUserDetails({
        firstName: user?.first_name,
        lastName: user?.last_name,
        email: user?.email,
      });
      console.log("user: ", user);
    };

    checkOriginalUserVerification();
  }, [dupAddressData]);

  const onContinue = async () => {
    try {
      const user = await getUserByEmail(userDetails.email);
      console.log("user: ", user);

      if (user.dup_address_otp === code) {
        const password = decrypt(user.password);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          userDetails.email,
          password
        );
        const userData = await getUserByEmail(userDetails.email);
        setRoleBasedRoute(userData.user_role);
        login(userCredential.user.accessToken);
        console.log(
          "dupAddressData.dup_address_otp: ",
          userData.dup_address_otp
        );
        // Update fraud user status
        const userRef = doc(db, "users", userData.id);

        // Get the original user with matching dup_address_token to get the household_id
        const originalUserQuery = query(
          collection(db, "users"),
          where("dup_address_token", "==", userData.dup_address_token),
          where("dup_address_owner", "==", true)
        );
        let originalUser;
        const originalUserSnapshot = await getDocs(originalUserQuery);
        if (originalUserSnapshot.empty) {
          originalUser = originalUserSnapshot.docs[0].data();
        }

        await updateDoc(userRef, {
          status: "active",
          disabled: false,
          dup_address: true,
          dup_address_owner: false,
          dup_address_token: userData.dup_address_token,
          household_id: originalUser.household_id,
        });

        // Navigate based on profile completion
        if (userData.profile_completed) {
          navigate("/home");
        } else {
          switch (userData.user_role) {
            case "center":
              navigate("/centerprofile");
              break;
            case "partner":
              navigate("/partnerprofile");
              break;
            case "citizen":
              navigate("/citizenprofile");
              break;
            default:
              navigate("/home");
          }
        }
      } else {
        console.log("dupaddress: ", dupAddressData);
        console.log("code: ", code);
        console.log("email: ", email);
        setIsInvalidCode(true);
        setTimeout(() => {
          setIsInvalidCode(false);
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const [showPopup, setShowPopup] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const resendCode = async () => {
    if (resendTimer > 0) return;

    try {
      const newOtp = generateOtp();

      const user = await getUserByEmail(auth.currentUser.email);
      if (!user) {
        throw new Error("User not found");
      }

      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        otp: newOtp,
      });

      await mailer.sendOtpEmail(
        auth.currentUser.email,
        newOtp,
        user.first_name
      );

      setResendTimer(60);
      setTimeout(() => setShowPopup(true), 2000);

      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      console.error("Error in resendCode:", err);
      alert("Failed to resend code. Please try again.");
    }
  };

  return (
    <>
      <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto   bg-[rgb(225,190,116)]/[0.13]">
        <div className="viewport-bg flex min-h-screen w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13] pb-2 pt-2">
          <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
            <div className="w-[450px] rounded-l-lg bg-white pb-2">
              <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
                <img
                  loading="lazy"
                  src={blueSkyLogo}
                  className="aspect-[0.99] w-[90px] object-contain"
                  alt="DARCI logo"
                />
                <label className="login-logo-label mt-2.5">DARCI</label>
              </header>
              <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center ">
                <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                  <div className="text-xl leading-none">
                    {/* <span className="text-base">Powered by </span> */}
                    <span className="text-sm text-blue-500">
                      Disaster Aware and Ready Communities
                    </span>
                  </div>
                  {/* <div className="mt-1.5 text-xs">Step 3 of 3</div>
                <div className="ml-1 mt-1.5 text-base leading-none">
                  Create account as a
                  <span className="ml-1 text-blue-500">{userType}</span>
                </div> */}
                </div>
              </div>
              <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
                <h2 className="text-xl max-md:max-w-full font-semibold leading-tight text-slate-600">
                  Duplicate Address Verification
                </h2>

                <div className="mt-4 flex">
                  <div>
                    <img
                      loading="lazy"
                      src={userIcon}
                      className="aspect-[0.99] w-[40px] object-contain"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                      {userDetails.email}
                    </p>
                    <p
                      className="max-md:max-w-full mt-2 whitespace-nowrap"
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {userDetails.lastName
                        ? userDetails.lastName
                        : "Last Name not provided"}
                      ,
                      {userDetails.firstName
                        ? userDetails.firstName
                        : "First Name not provided"}
                    </p>
                  </div>
                </div>

                <section className="max-md:max-w-full mt-2.5  flex w-full flex-col justify-center">
                  <p className="font-small max-md:max-w-full text-slate-600">
                    Your address has been flagged as a duplicate address. Please
                    enter the confirmation code sent to your household member's
                    email.
                  </p>
                  <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                    <input
                      type="text"
                      aria-label="Confirmation code"
                      className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] focus:outline-none"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                    {isInvalidCode ? (
                      <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                        You have entered an invalid code!
                      </div>
                    ) : (
                      <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                        {" "}
                      </div>
                    )}
                  </div>
                  <div
                    className="flex max-w-[483px] flex-col justify-center tracking-wide"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="max-md:max-w-full mt-3 flex w-full flex-col justify-center text-center">
                      <button
                        className="max-md:max-w-full bg-login-btn w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 hover:cursor-pointer"
                        onClick={onContinue}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
              <h3 className="pt-10 leading-relaxed text-white">
                Welcome to the DARCI Program, powered by Blue Sky.
                <br />
                <br />
                We are here to support your efforts in strengthening community
                preparedness and recovery.
                <br />
                <br />
                Access comprehensive tools, resources, and insights to enhance
                your leadership, response, and recovery capabilities.
                <br />
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddressVerify.propTypes = {
  className: PropTypes.string,
};

export default AddressVerify;
