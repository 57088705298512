import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import placeholderImage from "../icons/Opportunities/opportunities.png";
import { useEffect } from "react";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  updateDoc,
  where,
  query,
  getDocs,
  doc,
  getFirestore,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  TextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DatePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const VolunteerOpportunities = ({ className = "", onNavigateBack }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [volunteers, setVolunteers] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return "$0.00";
    return `$${amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const convertToDate = (timestamp) =>
    timestamp && timestamp.toDate
      ? timestamp.toDate().toLocaleDateString()
      : "N/A";

  const convertToTime = (timestamp) =>
    timestamp && timestamp.toDate
      ? timestamp
          .toDate()
          .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : "N/A";

  useEffect(() => {
    const auth = getAuth();
    console.log("Auth initialized");

    const fetchUserData = async (user) => {
      if (user) {
        try {
          console.log("Fetching user data for:", user.uid);
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log("User data fetched:", userData);
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsLoading(false);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User authenticated:", user.uid);
        fetchUserData(user);
      } else {
        console.log("No user authenticated");
        setUserData({});
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchVolunteers = async () => {
    if (!userData?.center_id) {
      console.log("No center_id available");
      setIsLoading(false);
      return;
    }

    try {
      console.log("Fetching volunteers for center:", userData.center_id);
      const volunteersQuery = query(
        collection(db, "opportunities"),
        where("type", "==", "volunteer"),
        where("center_id", "array-contains", userData.center_id),
        where("is_deleted", "==", false),
        where("published", "==", true)
      );

      const querySnapshot = await getDocs(volunteersQuery);
      const fetchedVolunteers = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        console.log("Fetched volunteer:", data);
        return {
          volunteer: { ...data, id: doc.id },
        };
      });

      console.log("Total volunteers fetched:", fetchedVolunteers.length);
      setVolunteers(fetchedVolunteers);
    } catch (error) {
      console.error("Error fetching volunteers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      console.log("User data updated, fetching volunteers");
      fetchVolunteers();
    }
  }, [userData]);

  const VolunteerCard = ({ volunteer, fetchVolunteers }) => {
    return (
      <>
        <div
          className={`flex w-[330px] flex-col items-end justify-start gap-[10px] rounded-mini border bg-white px-0 pb-[26px] pt-0 shadow-lg ${
            selectedVolunteer === volunteer
              ? "border-blue-500"
              : "border-gainsboro-200"
          } `}
          style={{
            border:
              selectedVolunteer === volunteer
                ? "2px solid #467ba4"
                : "1px solid gainsboro",
          }}
        >
          <div className="relative h-[190px] self-stretch">
            <img
              className="absolute left-0 top-0 z-[1] h-full w-full rounded-b-none rounded-t-mini object-cover"
              alt={volunteer.title}
              src={volunteer.backgroundImage || placeholderImage}
            />
          </div>
          <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[22px]">
            <div className="flex flex-1 flex-row items-start justify-between gap-5">
              <div className="flex flex-col items-start justify-start gap-2">
                <b className="relative z-[1] inline-block min-w-[58px]">
                  {volunteer.title}
                </b>
                <div className="relative z-[1] inline-block min-w-[57px] font-medium">
                  {volunteer.companyAddress}
                </div>
              </div>
              <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px text-right text-xs">
                <div className="flex flex-col items-end justify-start gap-2">
                  <div className="relative z-[1] inline-block min-w-[71px] font-medium">
                    {convertToDate(volunteer.date)}
                  </div>
                  <div className="relative z-[1] inline-block min-w-[107px] whitespace-nowrap font-medium">
                    {convertToTime(volunteer.startTime)} -{" "}
                    {convertToTime(volunteer.endTime)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-end self-stretch py-0 pl-[26px] pr-[21px] text-center text-white">
            <div
              onClick={() =>
                !volunteer.closed && setSelectedVolunteer(volunteer)
              }
              className={`z-[1] flex flex-1 cursor-pointer flex-row items-start justify-start rounded-8xs border-[1px] border-solid ${
                volunteer.closed
                  ? "cursor-not-allowed bg-gray-300 text-gray-500"
                  : "bg-header-panel text-[#FDB841] hover:bg-opacity-90"
              }`}
            >
              <b className="relative z-[1] flex h-10 flex-1 items-center justify-center">
                {volunteer.closed ? "Closed" : "View"}
              </b>
            </div>
          </div>
        </div>
      </>
    );
  };

  const VolunteerDetailsModal = ({ volunteer, onClose, currentUser }) => {
    if (!volunteer) return null;

    const handleApply = async () => {
      if (!currentUser || !currentUser.id) {
        alert("You must be logged in to apply.");
        return;
      }

      try {
        const applicationsRef = doc(
          db,
          "opportunities",
          volunteer.id,
          "applications",
          currentUser.id
        );

        await setDoc(
          applicationsRef,
          {
            userId: currentUser.id,
            appliedAt: new Date(),
            userName:
              `${currentUser.first_name} ${currentUser.last_name}` ||
              "Anonymous",
            email: currentUser.email || "No email provided",
          },
          { merge: true }
        );
        if (volunteer.companyWebsite) {
          window.open(volunteer.companyWebsite, "_blank");
        }
      } catch (error) {
        console.error("Error applying to volunteer:", error);
      }
    };

    return (
      <>
        <div className="fixed right-0 top-10 z-30 flex h-[100vh] w-[800px] items-center justify-end p-2">
          <div className="w-[800px] overflow-hidden rounded-lg bg-white shadow-xl">
            <div className="bg-header-panel sticky top-0 z-0 rounded-t-lg p-4">
              <div className="flex items-center justify-between">
                <h1 className="flex-1 text-start text-[20px] font-bold text-[#FDB841]">
                  {volunteer.title}
                </h1>
                <button
                  onClick={onClose}
                  className="bg-header-panel cursor-pointer p-2 text-[#FDB841] hover:text-gray-200"
                >
                  <svg
                    className="h-4 w-4"
                    fill="#FDB841"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="h-[80vh] overflow-y-auto">
              <div className="flex flex-col p-6">
                <div className="">
                  <div className="w-full">
                    <div className="relative h-[250px] self-stretch">
                      <img
                        className="absolute left-0 top-0 z-[1] h-full w-full rounded-b-none rounded-t-mini object-cover"
                        alt={volunteer.title}
                        src={volunteer.backgroundImage || placeholderImage}
                      />
                    </div>
                    <div className="my-3 flex w-full justify-end">
                      <button
                        className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                        type="button"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                    <div className="mb-3">
                      <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                        </svg>
                        <p className="text-xl font-bold">VOLUNTEER OVERVIEW</p>
                      </div>

                      <div className="rounded-lg  bg-white px-6">
                        <p className=" text-left text-black">
                          {volunteer.overview}
                        </p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="flex justify-between rounded-lg  bg-white px-6">
                        <div className="flex items-center gap-3">
                          <div className="text-[#467ba4]">
                            <svg
                              className="h-8 w-8"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                              <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm text-black">WORKING HOURS</p>
                            <p className="font-bold text-black">
                              {convertToTime(volunteer.startTime)} -{" "}
                              {convertToTime(volunteer.endTime)}
                            </p>
                          </div>
                        </div>

                        <div className="flex items-center gap-3">
                          <div className="text-[#467ba4]">
                            <svg
                              className="h-8 w-8"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z" />
                            </svg>
                          </div>
                          <div>
                            <p className="text-sm text-black">DATE POSTED</p>
                            <p className="font-bold text-black">
                              {convertToDate(volunteer.date)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                        </svg>
                        <p className="text-xl font-bold">SKILL REQUIREMENTS</p>
                      </div>

                      <div className="rounded-lg  bg-white px-6">
                        <p className="text-left text-black">
                          {volunteer.skillRequirements}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-4 flex items-center gap-2 text-[#467ba4]">
                      <svg
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
                      </svg>
                      <p className="text-xl font-bold">ABOUT THE EMPLOYER</p>
                    </div>

                    <div className="rounded-lg  bg-white px-6">
                      <div className="space-y-1 text-left text-black">
                        {[
                          {
                            label: "Company Name",
                            value: volunteer.companyName,
                          },
                          { label: "Industry", value: volunteer.industry },
                          {
                            label: "Company Address",
                            value: volunteer.companyAddress,
                          },
                          {
                            label: "Company Email",
                            value: volunteer.companyEmail,
                          },
                          {
                            label: "Company Contact Number",
                            value: volunteer.companyContactNumber,
                          },
                          {
                            label: "Volunteer Application URL",
                            value: volunteer.companyWebsite,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between"
                          >
                            <p className="w-1/2 text-sm text-black">
                              {item.label}
                            </p>
                            <p className="w-1/2 rounded bg-gray-200 p-2 font-medium text-black">
                              {item.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-h-[100vh] w-[800px] overflow-hidden rounded-lg bg-white shadow-xl"></div>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  return (
    <div
      className={`m-0 box-border flex w-full max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex w-full justify-between">
        <div className="w-full">
          <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
            <p className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Opportunities
            </p>
            <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
              /
            </div>
            <p className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
              Volunteer Opportunities
            </p>
          </div>

          <div className="mt-4 flex w-full flex-wrap gap-6 text-sm text-darkslategray-200">
            {volunteers.map((volunteer) => (
              <VolunteerCard
                volunteer={volunteer.volunteer}
                fetchVolunteers={fetchVolunteers}
              />
            ))}
          </div>
        </div>
        <div className="relative">
          {selectedVolunteer && !isEditModalOpen && (
            <VolunteerDetailsModal
              volunteer={selectedVolunteer}
              onClose={() => setSelectedVolunteer(null)}
              currentUser={userData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

VolunteerOpportunities.propTypes = {
  className: PropTypes.string,
};

export default VolunteerOpportunities;
