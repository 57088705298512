import React, { useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { encrypt, decrypt } from "../../service/encryption/Encryption";

const Password = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password don't match.");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      console.log("user: ", user.uid);

      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      // Update the user document in Firestore with the new password
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        password: encrypt(newPassword),
      });

      console.log("Password changed successfully");
      onClose();
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("Current password is incorrect.");
      } else {
        setError("An error occurred. Please try again.");
        console.error("Error changing password:", error);
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="w-[480px] rounded-lg bg-white shadow-xl">
        <div className="bg-blue flex items-center justify-between rounded-t-lg p-4">
          <h2 className="text-[24px] font-semibold">Change Password</h2>
          <button
            onClick={onClose}
            className="bg-blue cursor-pointer rounded-full text-[24px] text-white"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          {error && <div className="mb-4 text-red-500">{error}</div>}
          <div className="relative mb-4">
            <TextBoxComponent
              placeholder="Current Password"
              type={showPasswords.current ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              onPaste={(e) => e.preventDefault()}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility("current")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPasswords.current ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="relative mb-4">
            <TextBoxComponent
              placeholder="New Password"
              type={showPasswords.new ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              onPaste={(e) => e.preventDefault()}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility("new")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPasswords.new ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="relative mb-6">
            <TextBoxComponent
              placeholder="Confirm New Password"
              type={showPasswords.confirm ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              onPaste={(e) => e.preventDefault()}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility("confirm")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPasswords.confirm ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex items-center justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="btn-cancel cursor-pointer rounded-full border px-4 py-2 transition duration-300 hover:bg-blue-50"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="btn-default cursor-pointer rounded-full px-4 py-2 transition duration-300 hover:bg-blue-600"
            >
              SAVE CHANGES
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Password;
