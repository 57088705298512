import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

const generateUniqueID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

const citizenProfileService = {
  getCitizenProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  updateCitizenProfile: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const updatedData = { ...data, updated_at: new Date() };
      await updateDoc(docRef, updatedData);
      console.log("Document successfully updated!");
      return { id: docId, ...updatedData };
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  getHouseholdInfo: async (householdId) => {
    try {
      // Validate householdId
      if (!householdId || typeof householdId !== "string") {
        console.log("Invalid or missing household ID");
        return {
          annual_income: "",
          owner_or_renter: "",
          insurance: false,
          household_size: "",
          disability: false,
          military_vet: false,
          updated_at: null,
        };
      }

      const docRef = doc(
        db,
        "household",
        householdId,
        "household_info",
        "details"
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          annual_income: data.annual_income || "",
          owner_or_renter: data.owner_or_renter || "",
          insurance: Boolean(data.insurance),
          household_size: data.household_size || "",
          disability: Boolean(data.disability),
          military_vet: Boolean(data.military_vet),
          updated_at: data.updated_at ? data.updated_at.toDate() : null,
        };
      } else {
        console.log("No household info document found");
        return {
          annual_income: "",
          owner_or_renter: "",
          insurance: false,
          household_size: "",
          disability: false,
          military_vet: false,
          updated_at: null,
        };
      }
    } catch (error) {
      console.error("Error getting household info: ", error);
      throw error;
    }
  },

  updateHouseholdInfo: async (householdId, data) => {
    try {
      if (!householdId || typeof householdId !== "string") {
        throw new Error("Valid household ID string is required");
      }

      // Log the incoming data for debugging
      console.log("Incoming data:", data);

      // Define allowed fields and their default values
      const allowedFields = {
        annual_income: "",
        owner_or_renter: "",
        insurance: false,
        household_size: "",
        disability: false,
        military_vet: false,
        updated_at: new Date(),
      };

      // Ensure data is an object
      const inputData = data || {};

      // Clean the data by only including allowed fields
      const cleanData = Object.keys(allowedFields).reduce((acc, key) => {
        // Handle each field type appropriately
        if (key === "updated_at") {
          acc[key] =
            inputData[key] instanceof Date
              ? Timestamp.fromDate(inputData[key])
              : Timestamp.fromDate(new Date());
        } else if (typeof allowedFields[key] === "boolean") {
          acc[key] = Boolean(inputData[key]);
        } else {
          acc[key] = inputData[key]?.toString() || allowedFields[key];
        }
        return acc;
      }, {});

      // Log the cleaned data for debugging
      console.log("Cleaned data to save:", cleanData);

      const docRef = doc(
        db,
        "household",
        householdId,
        "household_info",
        "details"
      );

      // Always use setDoc without merge option to ensure clean replacement
      await setDoc(docRef, cleanData);

      return { id: "details", ...cleanData };
    } catch (error) {
      console.error("Error updating/creating household info document: ", error);
      throw error;
    }
  },

  // Get all household members for a citizen
  getHouseholdMembers: async (householdId) => {
    try {
      // Validate householdId
      if (!householdId || typeof householdId !== "string") {
        console.log("Invalid or missing household ID");
        return []; // Return empty array if no valid householdId
      }

      const householdMembersRef = collection(
        db,
        "household",
        householdId,
        "household_members"
      );
      const snapshot = await getDocs(householdMembersRef);

      // Map the documents to match the grid structure
      return snapshot.docs
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            householdMemberID: data.householdMemberID || doc.id,
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            date_of_birth: data.date_of_birth || "",
            relationship: data.relationship || "",
            disability: data.disability || "N/A",
            military_vet: data.military_vet || "No",
            is_deleted: data.is_deleted || false,
          };
        })
        .filter((member) => !member.is_deleted); // Filter out deleted members
    } catch (error) {
      console.error("Error getting household members: ", error);
      return []; // Return empty array on error
    }
  },

  // Add a new household member
  addHouseholdMember: async (householdId, memberData) => {
    try {
      console.log("Adding household member:", memberData); // Log the data being passed
      const docRef = await addDoc(
        collection(db, "household", householdId, "household_members"),
        memberData
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...memberData, id: docRef.id };
    } catch (error) {
      console.error("Error adding household member: ", error);
      throw error;
    }
  },

  // Update a household member
  updateHouseholdMember: async (householdId, householdMemberID, data) => {
    try {
      const memberRef = doc(
        db,
        "household",
        householdId,
        "household_members",
        householdMemberID
      );
      await setDoc(memberRef, data, { merge: true });
      console.log("Household member updated successfully");
      return { id: householdMemberID, ...data };
    } catch (error) {
      console.error("Error updating household member: ", error);
      throw error;
    }
  },

  // Get all pets for a citizen
  getPets: async (householdId) => {
    try {
      // Validate householdId
      if (!householdId || typeof householdId !== "string") {
        console.log("Invalid or missing household ID");
        return []; // Return empty array if no valid householdId
      }

      const petsRef = collection(db, "household", householdId, "pets");
      const snapshot = await getDocs(petsRef);

      // Map the documents to match the pets grid structure
      return snapshot.docs
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            pet_id: data.pet_id || doc.id,
            pet_name: data.pet_name || "",
            pet_color: data.pet_color || "",
            pet_breed: data.pet_breed || "",
            pet_size: data.pet_size || "",
            is_deleted: data.is_deleted || false,
            created_at: data.created_at || null,
            updated_at: data.updated_at || null,
          };
        })
        .filter((pet) => !pet.is_deleted); // Filter out deleted pets
    } catch (error) {
      console.error("Error getting pets: ", error);
      return []; // Return empty array on error instead of throwing
    }
  },

  // Add a new pet
  addPet: async (householdId, petData) => {
    try {
      const petID = generateUniqueID();
      const docRef = await addDoc(
        collection(db, "household", householdId, "pets"),
        {
          ...petData,
          created_at: new Date(),
          petId: petID, // Generate a unique ID for the new pet
        }
      );
      console.log("Document written with ID: ", docRef.id);
      return { ...petData, id: docRef.id, petId: docRef.id };
    } catch (error) {
      console.error("Error adding pet: ", error);
      throw error;
    }
  },

  // Update a pet
  updatePet: async (householdId, petId, petData) => {
    try {
      const petRef = doc(db, "household", householdId, "pets", petId);
      await setDoc(
        petRef,
        { ...petData, updated_at: new Date() },
        { merge: true }
      );
      console.log("Pet updated successfully");
      return { id: petId, ...petData };
    } catch (error) {
      console.error("Error updating pet: ", error);
      throw error;
    }
  },

  // Delete a pet
  deletePet: async (householdId, petId) => {
    try {
      console.log("Deleting pet:", householdId, petId);
      const petRef = doc(db, "household", householdId, "pets", petId);
      await deleteDoc(petRef);
      console.log("Pet deleted successfully");
    } catch (error) {
      console.error("Error deleting pet: ", error);
      throw error;
    }
  },

  toggleDisplaced: async (householdId, toggle) => {
    const userRef = doc(db, "users", householdId);
    try {
      console.log("Toggling displaced checkbox", householdId, toggle);
      await updateDoc(userRef, {
        is_displaced: toggle,
      });
      console.log("Displaced checkbox toggled!");
    } catch (error) {
      console.log("Error toggling displaced checkbox: ", error);
      throw error;
    }
  },

  updateDisplacedDetails: async (householdId, data) => {
    const userRef = doc(db, "users", householdId);
    try {
      console.log("Updating displaced details...", householdId, data);
      await updateDoc(userRef, {
        ...data,
        d_updated_at: new Date(),
      });
      console.log("Displaced details updated!");
    } catch (error) {
      console.log("Error updating displaced details: ", error);
      throw error;
    }
  },
};
export default citizenProfileService;
